.gen-swiper {

    &--category {
        margin: 0 30px;
    }

    &--product {
        margin: 0 10px;
    }

    &--category,
    &--product {
        padding-bottom: 20px;
        position: initial;        
    }

    @media only screen and (min-width: 1440px) {
        &--category,
        &--product {
            margin: 0 110px;
        }
    }

    @media only screen and (min-width: 1770px) {
        &--category,
        &--product {
            margin: 0 150px;
        }
    }

    &-button {
        position: absolute;
        z-index: 2;
        transform: translateY(-50%);
        width: 33px;
        height: 33px;            
        transition: all .2s ease-in-out;

        &--next {
            inset: 45% 12px auto auto;
            background: url('../../imgs/button-navigation-right.png') no-repeat center center / cover;            
        }

        @media only screen and (min-width: 1440px) {
            &--next {
                inset: 50% calc(var(--gen-container-offset) - 10px) auto auto;

                &:hover {
                    inset: 50% calc(var(--gen-container-offset) - 20px) auto auto;
                }
            }
        }
        
        &--prev {
            inset: 45% auto auto 12px;
            background: url('../../imgs/button-navigation-left.png') no-repeat center center / cover;            
        }
        
        @media only screen and (min-width: 1440px) {
            &--prev {
                inset: 50% auto auto calc(var(--gen-container-offset) - 10px);

                &:hover {
                    inset: 50% auto auto calc(var(--gen-container-offset) - 20px);
                }
            }
        }
    }

    @media only screen and (min-width: 1440px) {
        &-button {
            width: 69px;
            height: 62px;
        }
    }
}
.swiper-slide {

    position: relative;
    top: 0;
    transition: top .2s ease-in-out;

    &:nth-child(even) .gen-product-card__alternate {
        padding-top: 30px;
    }

    @media only screen and (min-width: 1440px) {
        &:nth-child(even) .gen-product-card__alternate {
            padding-top: 100px;
        }
    }

    &:hover {
        top: 10px;
    }
}