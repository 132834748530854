.gen-header {
    background-color: #f4b0b2;
    position: relative;
    z-index: 3;
    padding: 16px var(--gen-container-offset);
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__brand {
        position: relative;
        z-index: 1;
    }

    @media only screen and (min-width: 1200px) {
        &__brand img {
            width: 130px;
        }
    }

    &__button-group {
        display: flex;
        align-items: center;
        font-family: var(--gen-font-proxima-extrabold);
    }

    &__button-item {
        @include content-center;
        position: relative;
        gap: 10px;
        padding: var(--gen-menu-button-group-py) var(--gen-menu-button-group-px);

        span {
            display: none;
        }
    }
    
    &__icon-menu--inner {
        position: relative;
        width: 24px;
        height: 3px;

        &::before,
        &::after {
            content: "";
            display: block;
            width: 100%;
            height: 3px;
            background: #232950;
            border-radius: var(--gen-rounded-full);
            position: absolute;
        }

        &::before {
            top: -5px;
            transition: top .2s ease .22s,transform .2s ease,width .2s ease;
        }

        &::after {
            bottom: -5px;
            transition: bottom .2s ease .22s,transform .2s ease,width .2s ease;
        }

        &.active {
            
            &::before {
                top: 0;
                transform: rotate(45deg);
                transition: top .2s ease,transform .2s ease .22s,width .1s ease;
            }

            &::after {
                bottom: 0;
                transform: rotate(-45deg);
                transition: bottom .2s ease,transform .2s ease .22s,width .1s ease;
            }
        }
    }

    &__button-item--login {
        display: none;        
    }

    @media only screen and (min-width: 1367px) {
        &__button-item {        
            span {
                display: block;
            }
        }

        &__button-item--login {
            display: block;
        }

        &__icon-menu {
            display: none;
        }
    }    

    &__button-item--search {
        position: relative;
        border: 1px solid transparent;
        transition: all .2s ease-in-out;
        gap: 0;

        input {
            background: transparent;
            width: 0;
            transition: all .2s ease-in-out;
            outline: none;
            box-shadow: none;
        }

        @media only screen and (min-width: 1367px) {
            input {
                width: 52px;
            }
        }

        &:hover {
            border-color: #232950;
            border-radius: var(--gen-rounded-full);

            input {
                width: 115px;
                margin-left: 10px;
                
                &:focus {
                    +.gen-header__search-suggestion {
                        display: block;
                    }
                }
            }

            @media only screen and (min-width: 1367px) {
                input {
                    width: 165px;
                    margin-left: 0;
                }
            }
        }

        .gen-header__search-suggestion {
            background: #fff;
            padding: 20px;
            position: absolute;
            width: 220px;
            top: 45px;
            z-index: 1;
            left: 0;
            display: none;
            border-radius: 8px;
            box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.06);

            a {
                display: block;
                padding: 10px;
                transition: all .2s ease-in-out;

                &:hover {
                    padding-left: 16px;
                }
            }
        }
    }

    @media only screen and (min-width: 1367px) {
        &__button-item--search {
            gap: 10px;
            border: 3px solid transparent;
        }
    }

    &__menu-drawer {
        position: fixed;
        width: 100%;
        height: 100%;
        right: -100%;
        top: 0;
        background: #fff;
        padding: 80px var(--gen-container-offset) 20px;
        font-size: 22px;
        transition: all .2s ease-in-out;        

        &--opened {
            right: 0;
        }

        &-customer {
            margin: 50px 0;

            li {
                margin: 15px 0;
            }
        }

        &-newsletter {

            &--description {
                margin: 20px 0 26px;
                line-height: 26px;
            }
        }

        @media only screen and (min-width: 768px) {
            &-customer {
                margin: 30px 0 70px;
            }

            &-newsletter {

                &--title {
                    font-size: 22px;
                }
            }
        }

        &-inner {
            height: 100%;
            overflow: auto;
        }
    }

    @media only screen and (min-width: 768px) {
        &__menu-drawer {
            font-size: 15px;
            max-width: var(--gen-mobile-menu-width);
        }
    }

    &__menu-mobile-item {
        a {
            display: flex;
            align-items: center;
            gap: 12px;
            padding: 10px 0;
        }

        &--icon {
            @include content-center;
            width: 35px;
        }

        @media only screen and (min-width: 768px) {
            a {
                gap: 22px;
            }

            &--icon {
                width: 24px;
            }
        }
    }

}

@media only screen and (min-width: 1367px) {
    .gen-header {
        padding-top: 0;
        padding-bottom: 0;
    }
}

.gen-menu--opened {
    .gen-header {
        background: #fff;
    }
}

.gen-menu {
    display: none;
    align-items: center;

    &__link {
        display: flex;
        align-items: center;
        gap: 10px;
        font-size: 22px;
        padding: 8px 0;
    }

    &__link-icon {
        flex: 0 0 40px;
        display: flex;
        justify-content: center;
    }

    @media only screen and (min-width: 1680px) {
        &__link {
            padding: 10px 0;
        }
    }

    &__link--main {
        position: relative;
        font-size: 22px;
        display: block;
        padding: var(--gen-menu-main-link-py) var(--gen-menu-main-link-px);

        &::before {
            content: "";
            position: absolute;
            height: 2px;
            display: none;
            background: #232950;
            top: calc(100% - var(--gen-menu-main-link-py));
            left: 50%;
            transform: translateX(-50%);
            width: calc(100% - var(--gen-menu-main-link-px)*2);
        }
    }

    &__item {
        &:hover {
            .gen-menu__link--main::before {
                display: block;
            }

            .gen-mega-menu {
                opacity: 1;
                visibility: visible;
            }
        }

        .gen-mega-menu {
            position: absolute;
            left: 0;
            right: 0;
            opacity: 0;
            z-index: 10;
            visibility: hidden;
            height: 480px;

            &__content {
                width: 50%;
                padding: 20px 10px 80px 250px;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }

            @media only screen and (min-width: 1680px) {
                &__content {
                    padding: 20px 20px 120px 310px;
                }
            }

            @media only screen and (min-width: 1770px) {
                &__content {
                    padding: 20px 20px 190px 370px;
                }
            }

            &__column-title {
                font-size: 30px;
                margin-bottom: 30px;
            }
        }
    
        @media only screen and (min-width: 1680px) {
            .gen-mega-menu {
                height: 570px;
            }
        }

        @media only screen and (min-width: 1770px) {
            .gen-mega-menu {
                height: 640px;
            }
        }

        &:nth-of-type(1) .gen-mega-menu {
            background: url('../../imgs/bg-submenu-1.png') no-repeat center top / 100% auto;
        }
        &:nth-of-type(2) .gen-mega-menu {
            background: url('../../imgs/bg-submenu-2.png') no-repeat center top / 100% auto;
        }
        &:nth-of-type(3) .gen-mega-menu {
            background: url('../../imgs/bg-submenu-4.png') no-repeat center top / 100% auto;
        }
        &:nth-of-type(4) .gen-mega-menu {
            background: url('../../imgs/bg-submenu-3.png') no-repeat center top / 100% auto;
        }
        &:nth-of-type(5) .gen-mega-menu {
            background: url('../../imgs/bg-submenu-5.png') no-repeat center top / 100% auto;
        }
        &:nth-of-type(6) .gen-mega-menu {
            background: url('../../imgs/bg-submenu-5.png') no-repeat center top / 100% auto;
        }
        &:nth-of-type(7) .gen-mega-menu {
            background: url('../../imgs/bg-submenu-5.png') no-repeat center top / 100% auto;
        }
    }
}

@media only screen and (min-width: 1367px) {
    .gen-menu {
        display: flex;
    }
}