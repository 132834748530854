.gen-modal {
  position: fixed;
  transition: all 0.3s ease-in-out;
  z-index: 1000;
  inset: 50% auto auto 50%;
  visibility: hidden;
  opacity: 0;
  transform: translate(-50%, -40%);
  &.open {
    overflow: hidden;
    opacity: 1;
    visibility: visible;
    transform: translate(-50%, -50%);
  }
}