$breakpoints: (
    "sm": 576px,
    "md": 768px,
    "lg": 992px,
    "xl": 1200px,
    "xxl": 1400px
);

@mixin _applyBreakpoints() {
    @each $breakpoint-name, $breakpoint-value in $breakpoints {
        @media (min-width: $breakpoint-value) {
            @content ($breakpoint-name);
        }
    }
}