.gen-product-card {

    &__body {
        font-size: 12px;
    }

    @media only screen and (min-width: 768px) {
        &__body {
            font-size: 10px;
            line-height: 12px;
        }
    }

    @media only screen and (min-width: 1200px) {
        &__body {
            font-size: 23px;
            line-height: 28px;
        }
    }

    @media only screen and (min-width: 1440px) {
        &__body {
            font-size: 20px;
        }
    }

    @media only screen and (min-width: 1680px) {
        &__body {
            font-size: 23px;
        }
    }

    &__name {        
        text-align: center;
    }

    &__price {
        text-align: center;
        font-weight: 400;
        font-family: var(--gen-font-proxima-extrabold);
        // margin-top: 8px;
    }

    &__alternate {
        display: block;

        .gen-product-card {

            &__body {
                margin-top: 10px;
            }

            @media only screen and (min-width: 768px) {
                &__body {
                    margin-top: 16px;
                }
            }

            @media only screen and (min-width: 1200px) {
                &__body {
                    margin-top: 40px;
                    font-size: 40px;
                    line-height: 68px;
                }
            }

            &__name {
                font-size: 20px;
                font-family: var(--gen-font-heading);
                line-height: 1;
                white-space: nowrap;
            }

            @media only screen and (min-width: 1200px) {
                &__name {
                    font-size: 34px;
                }
            }

            @media only screen and (min-width: 1441px) {
                &__name {
                    font-size: 40px;
                }
            }
        }
    }
}