.gen-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: var(--gen-rounded-full);
    padding: 10px 16px;
    // font-size: 22px;
    font-size: 19px;

    &--dark-blue {
        background: #232950;
        color: #fff;
    }
}