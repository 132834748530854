$default-prefix: 'gen\\:';

$font-weight: (
	"font-thin": 100,
    "font-extralight": 200,
    "font-light": 300,
    "font-normal": 400,
    "font-medium": 500,
    "font-semibold": 600,
    "font-bold": 700,
    "font-extrabold": 800,
    "font-black": 900
);

@mixin _fontWeight($prefix, $value) {
	.#{$prefix} {
		font-weight: $value;
	}
}

@each $property, $value in $font-weight {
	@include _fontWeight($default-prefix + $property, $value);
}