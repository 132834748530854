$default-prefix: 'gen\\:';

/* ======================================================================
   Display
   ====================================================================== */
$displays: (
	"block": block,
	"inline-block": inline-block,
	"inline": inline,
	"flex": flex,
	"inline-flex": inline-flex,
	"grid": grid,
	"inline-grid": inline-grid,
	"hidden": none
);

@mixin _displays($prefix, $value) {
	.#{$prefix} {
		display: $value;
	}
}

@each $property, $value in $displays {
	@include _displays($default-prefix + $property, $value);
	@include _applyBreakpoints() using ($breakpoint-name) {
		@include _displays($breakpoint-name + '\\:' + $default-prefix + $property ,$value);
	}
}

/* ======================================================================
   Grid
   ====================================================================== */

/* Grid gap */
@mixin _gridGap ($prefix, $size) {
	.#{$prefix}gap-#{$size} {
		gap: $size + px;
	}
}

@mixin _gridGapX ($prefix, $size) {
	.#{$prefix}gap-x-#{$size} {
		column-gap: $size + px;
	}
}

@mixin _gridGapY ($prefix, $size) {
	.#{$prefix}gap-y-#{$size} {
		row-gap: $size + px;
	}
}

@for $i from 0 through 40 {
	@include _gridGap($default-prefix,$i * 2);
	@include _gridGapX($default-prefix,$i * 2);
	@include _gridGapY($default-prefix,$i * 2);
	@include _applyBreakpoints() using ($breakpoint-name) {
		@include _gridGap($breakpoint-name + '\\:' + $default-prefix ,$i * 2);
		@include _gridGapX($breakpoint-name + '\\:' + $default-prefix ,$i * 2);
		@include _gridGapY($breakpoint-name + '\\:' + $default-prefix ,$i * 2);
	}
}

/* Grid columns/rows start/end */
$grid-col-span-ex: (
	"col-auto": auto,
	"col-span-full": unquote("1 / -1"),
);

$grid-row-span-ex: (
	"row-auto": auto,
	"row-span-full": unquote("1 / -1"),
);

$col-start-ex: (
	"col-start-13": 13,
	"col-start-auto": auto
);

$col-end-ex: (
	"col-end-13": 13,
	"col-end-auto": auto
);

$row-start-ex: (
	"row-start-13": 13,
	"row-start-auto": auto
);

$row-end-ex: (
	"row-end-13": 13,
	"row-end-auto": auto
);

@mixin _gridColSpanEx ($prefix, $value) {
	.#{$prefix} {
		grid-column: $value;
	}
}

@each $property, $value in $grid-col-span-ex {
	@include _gridColSpanEx($default-prefix + $property, $value);
	@include _applyBreakpoints() using ($breakpoint-name) {
		@include _gridColSpanEx($breakpoint-name + '\\:' + $default-prefix + $property ,$value);
	}
}

@mixin _gridRowSpanEx ($prefix, $value) {
	.#{$prefix} {
		grid-row: $value;
	}
}

@each $property, $value in $grid-row-span-ex {
	@include _gridRowSpanEx($default-prefix + $property, $value);
	@include _applyBreakpoints() using ($breakpoint-name) {
		@include _gridRowSpanEx($breakpoint-name + '\\:' + $default-prefix + $property ,$value);
	}
}

@mixin _colStartEx ($prefix, $value) {
	.#{$prefix} {
		grid-column-start: $value;
	}
}

@each $property, $value in $col-start-ex {
	@include _colStartEx($default-prefix + $property, $value);
	@include _applyBreakpoints() using ($breakpoint-name) {
		@include _colStartEx($breakpoint-name + '\\:' + $default-prefix + $property ,$value);
	}
}

@mixin _colEndEx ($prefix, $value) {
	.#{$prefix} {
		grid-column-end: $value;
	}
}

@each $property, $value in $col-end-ex {
	@include _colEndEx($default-prefix + $property, $value);
	@include _applyBreakpoints() using ($breakpoint-name) {
		@include _colEndEx($breakpoint-name + '\\:' + $default-prefix + $property ,$value);
	}
}

@mixin _rowStartEx ($prefix, $value) {
	.#{$prefix} {
		grid-row-start: $value;
	}
}

@each $property, $value in $row-start-ex {
	@include _rowStartEx($default-prefix + $property, $value);
	@include _applyBreakpoints() using ($breakpoint-name) {
		@include _rowStartEx($breakpoint-name + '\\:' + $default-prefix + $property ,$value);
	}
}

@mixin _rowEndEx ($prefix, $value) {
	.#{$prefix} {
		grid-row-end: $value;
	}
}

@each $property, $value in $row-end-ex {
	@include _rowEndEx($default-prefix + $property, $value);
	@include _applyBreakpoints() using ($breakpoint-name) {
		@include _rowEndEx($breakpoint-name + '\\:' + $default-prefix + $property ,$value);
	}
}

@mixin _colSpan ($prefix, $column) {
	.#{$prefix}col-span-#{$column} {
		grid-column: span $column / span $column;
	}
}

@mixin _colStart ($prefix, $column) {
	.#{$prefix}col-start-#{$column} {
		grid-column-start: $column;
	}
}

@mixin _colEnd ($prefix, $column) {
	.#{$prefix}col-end-#{$column} {
		grid-column-end: $column;
	}
}

@mixin _rowSpan ($prefix, $row) {
	.#{$prefix}row-span-#{$row} {
		grid-row: span $row / span $row;
	}
}

@mixin _rowStart ($prefix, $row) {
	.#{$prefix}row-start-#{$row} {
		grid-row-start: $row;
	}
}

@mixin _rowEnd ($prefix, $row) {
	.#{$prefix}row-end-#{$row} {
		grid-row-end: $row;
	}
}

@for $i from 1 through 12 {
	@include _colSpan($default-prefix, $i );
	@include _colStart($default-prefix, $i );
	@include _rowSpan($default-prefix, $i );
	@include _rowStart($default-prefix, $i );
	@include _rowEnd($default-prefix, $i );
	@include _colEnd($default-prefix, $i );
	@include _applyBreakpoints() using ($breakpoint-name) {
		@include _colSpan($breakpoint-name + '\\:' + $default-prefix, $i );
		@include _colStart($breakpoint-name + '\\:' + $default-prefix, $i );
		@include _rowSpan($breakpoint-name + '\\:' + $default-prefix, $i );
		@include _rowStart($breakpoint-name + '\\:' + $default-prefix, $i );
		@include _rowEnd($breakpoint-name + '\\:' + $default-prefix, $i );
		@include _colEnd($breakpoint-name + '\\:' + $default-prefix, $i );
	}
}

/* Grid template columns/rows */
$grid-cols-ex: (
	"grid-cols-none": none,
	"grid-cols-subgrid": subgrid
);

$grid-rows-ex: (
	"grid-rows-none": none,
	"grid-rows-subgrid": subgrid
);

@mixin _gridColsEx ($prefix, $value) {
	.#{$prefix} {
		grid-template-columns: $value;
	}
}

@each $property, $value in $grid-cols-ex {
	@include _gridColsEx($default-prefix + $property, $value);
	@include _applyBreakpoints() using ($breakpoint-name) {
		@include _gridColsEx($breakpoint-name + '\\:' + $default-prefix + $property ,$value);
	}
}

@mixin _gridRowsEx ($prefix, $value) {
	.#{$prefix} {
		grid-template-rows: $value;
	}
}

@each $property, $value in $grid-rows-ex {
	@include _gridRowsEx($default-prefix + $property, $value);
	@include _applyBreakpoints() using ($breakpoint-name) {
		@include _gridRowsEx($breakpoint-name + '\\:' + $default-prefix + $property ,$value);
	}
}

@mixin _gridCols ($prefix, $column) {
	.#{$prefix}grid-cols-#{$column} {
		grid-template-columns: repeat($column, minmax(0, 1fr));
	}
}

@mixin _gridColSpan ($prefix, $column) {
	.#{$prefix}col-span-#{$column} {
		grid-column: auto/span + ' ' + $column;
	}
}

@mixin _gridRows ($prefix, $row) {
	.#{$prefix}grid-rows-#{$row} {
		grid-template-rows: repeat($row, minmax(0, 1fr));
	}
}

@mixin _gridRowSpan ($prefix, $row) {
	.#{$prefix}row-span-#{$row} {
		grid-row: auto/span + ' ' + $row;
	}
}

@for $i from 1 through 12 {
	@include _gridCols($default-prefix, $i );
	@include _gridColSpan($default-prefix, $i );
	@include _gridRows($default-prefix, $i );
	@include _gridRowSpan($default-prefix, $i );
	@include _applyBreakpoints() using ($breakpoint-name) {
		@include _gridCols($breakpoint-name + '\\:' + $default-prefix, $i );
		@include _gridColSpan($breakpoint-name + '\\:' + $default-prefix, $i );
		@include _gridRows($breakpoint-name + '\\:' + $default-prefix, $i );
		@include _gridRowSpan($breakpoint-name + '\\:' + $default-prefix, $i );
	}
}

/* ======================================================================
   Flex 
   ====================================================================== */
$flex: (
	"flex-1": 1 1 0%, 
	"flex-auto": 1 1 auto,
	"flex-initial": 0 1 auto,
	"flex-none": none
);

@mixin _flex($prefix, $value) {
	.#{$prefix} {
		flex: $value;
	}
}

@each $property, $value in $flex {
	@include _flex($default-prefix + $property, $value);
	@include _applyBreakpoints() using ($breakpoint-name) {
		@include _flex($breakpoint-name + '\\:' + $default-prefix + $property ,$value);
	}
}

/* Flex direction */
$flex-directions: row, row-reverse, column, column-reverse;
@mixin _flexDirection($prefix, $direction) {	

	@if $direction == column {
		.#{$prefix}col {
			flex-direction: $direction;
		}
	} @else if $direction == column-reverse {
		.#{$prefix}col-reverse {
			flex-direction: $direction;
		}
	} @else {
		.#{$prefix}#{$direction} {
			flex-direction: $direction;
		}
	}
}

@each $flex-direction in $flex-directions {
	@include _flexDirection('gen\\:flex-', $flex-direction);
	@include _applyBreakpoints() using ($breakpoint-name) {
		@include _flexDirection($breakpoint-name + '\\:' + $default-prefix + 'flex-' ,$flex-direction);
	}
}

/* Flex wrap */
$flex-wraps: wrap, wrap-reverse, nowrap;
@mixin _flexWrap($prefix, $wrap) {
	.#{$prefix}#{$wrap} {
		flex-wrap: $wrap;
	}
}

@each $flex-wrap in $flex-wraps {
	@include _flexWrap('gen\\:flex-', $flex-wrap);
	@include _applyBreakpoints() using ($breakpoint-name) {
		@include _flexWrap($breakpoint-name + '\\:' + $default-prefix + 'flex-' ,$flex-wrap);
	}
}

/* Flex grow */
$flex-grow: (
	"grow": 1, 
	"grow-0": 0
);

@mixin _flexGrow($prefix, $value) {
	.#{$prefix} {
		flex-grow: $value;
	}
}

@each $property, $value in $flex-grow {
	@include _flexGrow($default-prefix + $property, $value);
	@include _applyBreakpoints() using ($breakpoint-name) {
		@include _flexGrow($breakpoint-name + '\\:' + $default-prefix + $property ,$value);
	}
}

/* Flex shrink */
$flex-shrink: (
	"shrink": 1, 
	"shrink-0": 0
);

@mixin _flexShrink($prefix, $value) {
	.#{$prefix} {
		flex-shrink: $value;
	}
}

@each $property, $value in $flex-shrink {
	@include _flexShrink($default-prefix + $property, $value);
	@include _applyBreakpoints() using ($breakpoint-name) {
		@include _flexShrink($breakpoint-name + '\\:' + $default-prefix + $property ,$value);
	}
}

/* ======================================================================
   Justify + Align
   ====================================================================== */

/* Justify content */
$justify-content: (
	"justify-normal": normal,
	"justify-start": flex-start,
	"justify-end": flex-end,
	"justify-center": center,
	"justify-between": space-between,
	"justify-around": space-around,
	"justify-evenly": space-evenly,
	"justify-stretch": stretch,
);

@mixin _justifyContent($prefix, $value) {
	.#{$prefix} {
		justify-content: $value;
	}
}

@each $property, $value in $justify-content {
	@include _justifyContent($default-prefix + $property, $value);
	@include _applyBreakpoints() using ($breakpoint-name) {
		@include _justifyContent($breakpoint-name + '\\:' + $default-prefix + $property ,$value);
	}
}

/* Justify items */
$justify-items: (
	"justify-items-start": start,
	"justify-items-end": end,
	"justify-items-center": center,
	"justify-items-stretch": stretch
);

@mixin _justifyItems($prefix, $value) {
	.#{$prefix} {
		justify-items: $value;
	}
}

@each $property, $value in $justify-items {
	@include _justifyItems($default-prefix + $property, $value);
	@include _applyBreakpoints() using ($breakpoint-name) {
		@include _justifyItems($breakpoint-name + '\\:' + $default-prefix + $property ,$value);
	}
}

/* Justify self */
$justify-self: (
	"justify-self-auto": auto,
	"justify-self-start": start,
	"justify-self-end": end,
	"justify-self-center": center,
	"justify-self-stretch": stretch
);

@mixin _justifySelf($prefix, $value) {
	.#{$prefix} {
		justify-self: $value;
	}
}

@each $property, $value in $justify-self {
	@include _justifySelf($default-prefix + $property, $value);
	@include _applyBreakpoints() using ($breakpoint-name) {
		@include _justifySelf($breakpoint-name + '\\:' + $default-prefix + $property ,$value);
	}
}

/* Align content */
$align-content: (
	"content-normal": normal,
	"content-center": center,
	"content-start": flex-start,
	"content-end": flex-end,
	"content-between": space-between,
	"content-around": space-around,
	"content-evenly": space-evenly,
	"content-baseline": baseline,
	"content-stretch": stretch
);

@mixin _alignContent($prefix, $value) {
	.#{$prefix} {
		align-content: $value;
	}
}

@each $property, $value in $align-content {
	@include _alignContent($default-prefix + $property, $value);
	@include _applyBreakpoints() using ($breakpoint-name) {
		@include _alignContent($breakpoint-name + '\\:' + $default-prefix + $property ,$value);
	}
}

/* Align items */
$align-items: (
	"items-start": flex-start,
	"items-end": flex-end,
	"items-center": center,
	"items-baseline": baseline,
	"items-stretch": stretch
);

@mixin _alignItems($prefix, $value) {
	.#{$prefix} {
		align-items: $value;
	}
}

@each $property, $value in $align-items {
	@include _alignItems($default-prefix + $property, $value);
	@include _applyBreakpoints() using ($breakpoint-name) {
		@include _alignItems($breakpoint-name + '\\:' + $default-prefix + $property ,$value);
	}
}

/* Align Self */
$align-self: (
	"self-auto": auto,
	"self-start": flex-start,
	"self-end": flex-end,
	"self-center": center,
	"self-stretch": stretch,
	"self-baseline": baseline
);

@mixin _alignSelf($prefix, $value) {
	.#{$prefix} {
		align-self: $value;
	}
}

@each $property, $value in $align-self {
	@include _alignSelf($default-prefix + $property, $value);
	@include _applyBreakpoints() using ($breakpoint-name) {
		@include _alignSelf($breakpoint-name + '\\:' + $default-prefix + $property ,$value);
	}
}

/* ======================================================================
   Text align
   ====================================================================== */

$text-align: (
	"text-left": left,
	"text-center": center,
	"text-right": right,
	"text-justify": justify,
	"text-start": start,
	"text-end": end
);

@mixin _textAlign($prefix, $value) {
	.#{$prefix} {
		text-align: $value;
	}
}

@each $property, $value in $text-align {
	@include _textAlign($default-prefix + $property, $value);
	@include _applyBreakpoints() using ($breakpoint-name) {
		@include _textAlign($breakpoint-name + '\\:' + $default-prefix + $property ,$value);
	}
}