.gen-dropdown {
    position: relative;
    color: #1F1F27;

    &__button {
        display: flex;
        align-items: center;
        gap: 8px;
        border: 3px solid #fff;
        border-radius: 99px;
        padding: 7px 16px;
        white-space: nowrap;
    }

    &__button--icon {
        transition: all .2s linear;
    }

    &__menu {
        position: absolute;        
        visibility: hidden;
        opacity: 0;
        width: max-content;
        z-index: 2;
        transition: all .2s linear;
        background: #fff;
        padding: 8px;
        text-align: left;
        border-radius: 8px;
        box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.06);
    }

    &--bottom {
        &-right {
            .gen-dropdown__menu {
                right: 0;
                left: unset !important;
            }
        }
        .gen-dropdown__menu {
            left: 0;
            top: calc(100% + 40px);
        }

        &.gen-dropdown--opened {

            .gen-dropdown__menu {
                top: calc(100% + 10px);
            }
        }
    }
    
    &--top {
        .gen-dropdown__menu {
            right: 0;
            bottom: calc(100% + 40px);
        }

        &.gen-dropdown--opened {            

            .gen-dropdown__menu {
                bottom: calc(100% + 10px);
            }
        }
    }

    &.gen-dropdown--opened {

        .gen-dropdown__menu {
            visibility: visible;
            opacity: 1;
        }

        .gen-dropdown__button--icon {
            transform: rotate(180deg);
        }
    }

    &__menu-item {
        padding: 10px 16px;
        cursor: pointer;
        color: #515167;

        &:hover {
            background: #EFF0F2;
            color: #1F1F27;
        }
    }    
}