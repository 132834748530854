@font-face {
    font-family: "Embryo";
    src: url("https://db.onlinewebfonts.com/t/95d986cf6225d38d607da8e242d53add.eot");
    src: url("https://db.onlinewebfonts.com/t/95d986cf6225d38d607da8e242d53add.eot?#iefix")format("embedded-opentype"),
    url("https://db.onlinewebfonts.com/t/95d986cf6225d38d607da8e242d53add.woff2")format("woff2"),
    url("https://db.onlinewebfonts.com/t/95d986cf6225d38d607da8e242d53add.woff")format("woff"),
    url("https://db.onlinewebfonts.com/t/95d986cf6225d38d607da8e242d53add.ttf")format("truetype"),
    url("https://db.onlinewebfonts.com/t/95d986cf6225d38d607da8e242d53add.svg#Embryo")format("svg");
}

@font-face {
    font-family: "ProximaNova-Extrabold";
    src: url("https://db.onlinewebfonts.com/t/5243e362912f7119ede836ab03f23ac7.eot");
    src: url("https://db.onlinewebfonts.com/t/5243e362912f7119ede836ab03f23ac7.eot?#iefix")format("embedded-opentype"),
    url("https://db.onlinewebfonts.com/t/5243e362912f7119ede836ab03f23ac7.woff2")format("woff2"),
    url("https://db.onlinewebfonts.com/t/5243e362912f7119ede836ab03f23ac7.woff")format("woff"),
    url("https://db.onlinewebfonts.com/t/5243e362912f7119ede836ab03f23ac7.ttf")format("truetype"),
    url("https://db.onlinewebfonts.com/t/5243e362912f7119ede836ab03f23ac7.svg#Proxima Nova Extrabold")format("svg");
}

@font-face {
    font-family: "ProximaNova-Black";
    src: url("https://db.onlinewebfonts.com/t/3c6c5a0069d1059216b0bb7817b945a3.eot");
    src: url("https://db.onlinewebfonts.com/t/3c6c5a0069d1059216b0bb7817b945a3.eot?#iefix")format("embedded-opentype"),
    url("https://db.onlinewebfonts.com/t/3c6c5a0069d1059216b0bb7817b945a3.woff2")format("woff2"),
    url("https://db.onlinewebfonts.com/t/3c6c5a0069d1059216b0bb7817b945a3.woff")format("woff"),
    url("https://db.onlinewebfonts.com/t/3c6c5a0069d1059216b0bb7817b945a3.ttf")format("truetype"),
    url("https://db.onlinewebfonts.com/t/3c6c5a0069d1059216b0bb7817b945a3.svg#ProximaNova-Black")format("svg");
}

/* SCSS Shared properties */
@mixin content-center($display: flex) {
    display: $display;
    align-items: center;
    justify-content: center;
}

@import 'reset.css';
@import './modules/breakpoints';
@import './modules/grid';
@import './modules/typo';
@import './modules/header';
@import './modules/button';
@import './modules/modal';
@import './modules/swiper-product';
@import './modules/product-card';
@import './modules/homepage';
@import './modules/decor';
@import './modules/dropdown';

:root {

    /* Font Proxima Nova Extrabold */
    --gen-font-proxima-extrabold: 'ProximaNova-Extrabold';

    /* Font Body */        
    --gen-font-body: 'ProximaNova-Black';
    --gen-font-weight-body: 400;
    --gen-font-color-body: #232950;
    --gen-font-size-body: 18px;
    --gen-line-height-body: 22px;

    /* Font Heading */
    --gen-font-heading: 'Embryo';
    --gen-font-heading-color: #1F1F27;
    --gen-font-size-4xl: 56px;
    --gen-line-height-4xl: 65px;
    --gen-font-size-3xl: 45px;
    --gen-line-height-3xl: 60px;
    --gen-font-size-2xl: 36px;
    --gen-line-height-2xl: 48px;
    --gen-font-size-xl: 26px;
    --gen-line-height-xl: 40px;
    --gen-font-size-lg: 20px;
    --gen-line-height-lg: 28px;
    --gen-font-size-base: 16px;
    --gen-line-height-base: 20px;

    /* Font Heading Slide */
    --gen-font-size-heading-hero: 72px;
    --gen-line-height-heading-hero: 84px;

    /* Rounded */
    --gen-rounded-sm: 4px;
    --gen-rounded-md: 8px;
    --gen-rounded-lg: 12px;
    --gen-rounded-xl: 16px;
    --gen-rounded-full: 100px;

    /* Container */
    --gen-container-width: 1500px;
    --gen-container-width-fluid: 1920px;
    --gen-container-offset: 30px;

    /* Product price color */
    --gen-product-price-regular: #1F1F27;
    --gen-product-price-sale: #FB4005;

    /* Main menu */
    --gen-menu-main-link-px: 35px;
    --gen-menu-main-link-py: 54px;
    --gen-menu-dropdown-px: 40px;
    --gen-menu-dropdown-py: 30px;
    --gen-menu-button-group-px: 10px;
    --gen-menu-button-group-py: 10px;

    /* Section */
    --gen-section-body-py: 20px;

    /* Mobile menu */
    --gen-mobile-menu-width: 300px;
}

@media only screen and (min-width: 768px) {
    :root {

        /* Container */
        --gen-container-offset: 40px;
    }
}
@media only screen and (min-width: 1200px) {
    :root {        
        
        /* Main menu */
        --gen-menu-main-link-px: 15px;
        --gen-menu-main-link-py: 34px;

        /* Section */
        --gen-section-body-py: 70px;
    }
}

@media only screen and (min-width: 1680px) {
    :root {

        /* Container */
        --gen-container-offset: 58px;

        /* Main menu */
        --gen-menu-main-link-px: 25px;
        --gen-menu-main-link-py: 54px;
    }
}

@media only screen and (min-width: 1770px) {
    :root {
        --gen-menu-main-link-px: 35px;
    }
}


body {
    margin: 0;
    padding: 0;
    color: var(--gen-font-color-body);
    font-family: var(--gen-font-body);
    font-size: var(--gen-font-size-body);
    line-height: var(--gen-line-height-body);
    scroll-behavior: smooth;
}

h1,h2,h3,h4,h5,
.h1,.h2,.h3,.h4,.h5 {
    font-family: var(--gen-font-heading);
    color: var(--gen-font-heading-color);
}
h1, .h1, .gen-text--3xl {
    font-size: var(--gen-font-size-3xl);
    line-height: var(--gen-line-height-3xl);
}
h2, .h2, .gen-text--2xl {
    font-size: var(--gen-font-size-2xl);
    line-height: var(--gen-line-height-2xl);
}
h3, .h3, .gen-text--xl {
    font-size: var(--gen-font-size-xl);
    line-height: var(--gen-line-height-xl);
}
h4, .h4, .gen-text--lg {
    font-size: var(--gen-font-size-lg);
    line-height: var(--gen-line-height-lg);
}
h5, .h5, .gen-text--base {
    font-size: var(--gen-font-size-base);
    line-height: var(--gen-line-height-base);
}
.gen-text--4xl {
    font-size: var(--gen-font-size-4xl);
    line-height: var(--gen-line-height-4xl);
}
.gen-text--hero {
    font-size: var(--gen-font-size-heading-hero);
    line-height: var(--gen-line-height-heading-hero);
}
.gen\:w-full {
    width: 100%;
}
.gen\:overflow-hidden {
    overflow: hidden
}
.gen-content-center {
    @include content-center;
}

/* Font */
/* Container */
.gen-container,
.gen-container--fluid {
    width: 100%;    
    margin: 0 auto;
    padding: 0 var(--gen-container-offset);
}
.gen-container {
    max-width: var(--gen-container-width);
}
.gen-container--fluid {
    max-width: var(--gen-container-width-fluid);
}
.gen-container--full {
    width: 100%;
}

.gen\:text-white {
    color: white;
}
.gen-underline {
    text-decoration: underline;
}

/*  */
.gen\:visually-hidden {
    width: 1px;
    height: 1px;
    padding: 0;
    margin: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    white-space: nowrap;
    border: 0;
    position: absolute;
}

/* Rounded */
.gen-rounded--sm {
    border-radius: var(--gen-rounded-sm);
}
.gen-rounded--md {
    border-radius: var(--gen-rounded-md);
}
.gen-rounded--lg {
    border-radius: var(--gen-rounded-lg);
}
.gen-rounded--xl {
    border-radius: var(--gen-rounded-xl);
}
.gen-rounded--full {
    border-radius: var(--gen-rounded-full);
}

/* Responsive image */
[style*="--aspect-ratio"] {

    position: relative;
    overflow: hidden;

    &::after {
        content: "";
        display: block;
        width: 100%;
        height: auto;
        aspect-ratio: var(--aspect-ratio, 16/9);
    }

    img,
    video {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}

.gen-wrap {
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
}

/* Backdrop */
.gen-backdrop {
    position: absolute;
    inset: 0 0 0 0;
    background: rgba(0,0,0,.3);
    opacity: 0;
    visibility: hidden;
    transition: all .2s ease-in-out;

    &-active {
        .gen-backdrop {
            z-index: 3;
            visibility: visible;
            opacity: 1;
        }

        @media only screen and (max-width: 1367px) {
            .gen-backdrop {
                z-index: 4;
                inset: 0 var(--gen-mobile-menu-width) 0 0;
            }
        }

        @media only screen and (max-width: 576px) {
            .gen-backdrop {
                z-index: 3;
            }
        }
    }
}

/* Section */
section {
    position: relative;

    [class*="gen-decor"] {
        position: absolute;
        z-index: 1;
        display: none;
    }

    @media only screen and (min-width: 1440px) {
        [class*="gen-decor"] {
            display: block;
        }
    }    
}

.gen-section {

    &__header {
        padding-top: 20px;
    }

    &__body {
        padding: var(--gen-section-body-py) var(--gen-container-offset);
        position: relative;
        z-index: 2;
    }

    &--1 {
        background: #F4B0B2;
    }

    &--2 {
        background: #F9D2D1;

        &::before {
            content: "";
            display: block;
            width: 100%;
            height: 40px;
            background: url(../imgs/wave-1.png) no-repeat center top;
            background-size: 100% auto;
        }

        &::after {
            content: "";
            display: block;
            width: 100%;
            height: 80px;
            background: url(../imgs/wave-2.png) no-repeat center bottom;
            background-size: 463px auto;
        }

        @media only screen and (min-width: 768px) {
            &::before {
                height: 80px;
            }

            &::after {
                height: 140px;
                background-size: 976px auto;
            }
        }

        @media only screen and (min-width: 1024px) {
            &::after {
                height: 190px;
                background-size: 1200px auto;
            }
        }

        @media only screen and (min-width: 1366px) {
            &::before {
                height: 140px;
            }

            &::after {
                height: 270px;
                background-size: 1650px auto;
            }

            .gen-decor--1 {
                left: -80px;
                bottom: 40px;
                width: 250px;
            }
    
            .gen-decor--2 {
                right: -100px;
                bottom: 180px;
                width: 190px;
            }
        }

        @media only screen and (min-width: 1680px) {
            &::before {
                margin-bottom: 80px;
            }

            &::after {
                height: 350px;
                background-size: 2210px auto;
            }
    
            .gen-decor--2 {
                bottom: 250px;
            }
        }        
    }

    &--3 {
        background: #F29A75;

        &::after {
            content: "";
            display: block;
            width: 100%;
            height: 60px;
            background: url(../imgs/wave-3.png) no-repeat center top;
            background-size: 100% auto;
        }

        @media only screen and (min-width: 1366px) {
            &::after {
                height: 110px;
            }

            .gen-decor--3 {
                right: -70px;
                bottom: -80px;
                width: 260px;
            }
        }
    }

    &--4 {
        background: #13789A;

        @media only screen and (min-width: 1440px) {
            .gen-decor--4 {
                bottom: 0px;
                left: 30px;
                width: 180px;
            }

            .gen-decor--5 {
                left: 50%;
                bottom: 0;
                width: 50px;
            }

            .gen-decor--6 {
                right: 80px;
                bottom: 50px;
                width: 60px;
            }
        }

        @media only screen and (min-width: 1770px) {
            .gen-section__header {
                padding-top: 110px;
            }

            .gen-decor--6 {
                bottom: 80px;
            }
        }
    }

    &--5 {
        background: #F3E358;

        &::before {
            content: "";
            display: block;
            width: 100%;
            height: 70px;
            background: url(../imgs/wave-4.png) no-repeat right top;
            background-size: 850px auto;
        }

        @media only screen and (min-width: 768px) {
            &::before {
                height: 90px;
                background-size: 1240px auto;
                background-position: center top;
            }
        }

        @media only screen and (min-width: 1366px) {
            &::before {
                height: 160px;
                background-size: 2940px auto;
                background-position: right bottom;
            }
        }        
    }

    &__heading {
        text-align: center;
        font-family: var(--gen-font-heading);
        font-size: 30px;
        line-height: 53px;
        color: #232950;

        &.gen\:text-white {
            color: #fff;
        }
    }

    @media only screen and (min-width: 1200px) {
        &__heading {
            font-size: 54px;
            line-height: 104px;
        }
    }

    @media only screen and (min-width: 1680px) {
        &__heading {
            font-size: 61px;
        }
    }
}

/* Banner */
.gen-banner {

    .gen-section__body {
        padding-top: 0;
        padding-bottom: 30px;
    }

    &__content {
        display: flex;
        flex-direction: column;
        place-items: center;
        justify-content: center;

        &-image {
            transform: rotate(3deg);
        }

        &-title {
            margin-bottom: 60px;
        }

        &-subtitle {
            width: min(296px, 100%);
            margin: 16px 0 24px;
            text-align: center;
            font-size: 19px;
            line-height: 23px;
        }

        @media only screen and (min-width: 768px) {
            &-subtitle {
                width: min(205px, 100%);
                font-size: 15px;
                line-height: 18px;
            }
        }

        @media only screen and (min-width: 1200px) {
            &-subtitle {
                width: min(474px, 100%);
                margin: 60px 0 40px;                
                font-size: 30px;
                line-height: 40px;
            }
        }
    }

    @media only screen and (min-width: 768px) {
        .gen-decor {
            position: absolute;
            display: block;
            right: -60px;
            top: 100px;
            width: 140px;
        }
    }   
    
    @media only screen and (min-width: 1024px) {
        .gen-decor {
            top: 150px;
        }
    }   

    @media only screen and (min-width: 1440px) {
        .gen-decor {
            width: 180px;
            top: 400px;
            right: -80px;
        }
    }

    @media only screen and (min-width: 1770px) {
        .gen-decor {
            top: 510px;
            width: 230px;
            right: -100px;
        }
    }

    .gen-readmore {
        .gen-btn {
            min-width: 200px;
        }
    }
}

/* Partners */
.gen-partners {

    a {
        @include content-center;
        position: relative;
        top: 0;
        transition: all .2s ease-in-out;

        &:hover {
            top: 10px;
        }

        img {
            margin: 0 auto;
        }
    }
}

/* Form */
::-webkit-input-placeholder {
    color: #232950;
}

::-moz-placeholder {
    color: #232950;
}

::-ms-placeholder {
    color: #232950;
}

::placeholder {
    color: #232950;
}

.gen-form-input {
    border: 3px solid #232950;
    border-radius: var(--gen-rounded-full);
    background: none;
    padding: 6px 16px;
    outline: none;
}

/* Footer */
.gen-footer {
    font-size: 18px;
    line-height: 22px;
    position: relative;
    padding: 100px var(--gen-container-offset) 40px;
    background: #F3E358 url(../imgs/bg-footer.png) no-repeat bottom -170px right -350px;

    &__title {
        font-family: var(--gen-font-body);
        color: var(--gen-font-color-body);
        margin-bottom: 32px;
        font-size: 18px;
    }

    &__menu {
        white-space: nowrap;

        &-item:not(:last-child) {
            margin-bottom: 14px;
        }
    }

    &__content {
        margin-bottom: 32px;
    }

    &__newsletter-form {
        padding-top: 10px;
    }

    &__social {
        display: flex;
        align-items: flex-end;
        color: #fff;
    }

    @media only screen and (min-width: 768px) {
        &__social {
            display: none;
        }
    }

    @media only screen and (min-width: 1200px) {
        &__social {
            display: flex;
        }

        &__leftside {
            flex: 0 0 60%;
        }

        &__rightside {
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
            flex-grow: 1;
        }
    }

    @media only screen and (min-width: 1680px) {
        &__social {
            margin-left: 80px;
        }
    }

    &__rightside-content {
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding-top: 230px;
    }

    @media only screen and (min-width: 768px) {
        &__rightside-content {
            flex-direction: row;
            gap: 42px;
        }
    }
    
    @media only screen and (min-width: 1200px) {
        &__rightside-content {
            padding-top: 0;
            gap: 20px;
            align-items: center;
        }
    }

    &__copyright {
        margin-top: -25px;
        right: var(--gen-container-offset);
        position: absolute;
        color: #fff;
        font-family: var(--gen-font-proxima-extrabold);
    }

    @media only screen and (min-width: 1200px) {
        &__copyright {
            margin-top: 40px;
            right: auto;
            color: inherit;
        }
    }
}

@media only screen and (min-width: 768px) {
    .gen-footer {
        font-size: 12px;
        padding-top: 40px;
        background-position: bottom -270px right -110px;

        &__column {
            flex: 1;
        }

        &__title {
            font-size: 12px;
            margin-bottom: 0;
        }

        &__menu {
    
            &-item:not(:last-child) {
                margin-bottom: 0;
            }
        }

        &__content {
            margin-bottom: 16px;
        }
    }
}

@media only screen and (min-width: 1024px) {
    .gen-footer {
        background-position: bottom -440px right -150px;
        background-size: 1300px auto;

        &__rightside-content {
            padding-top: 280px;
        }
    }
}

@media only screen and (min-width: 1200px) {
    .gen-footer {
        font-size: 13px;
        padding-top: 60px;
        padding-bottom: 80px;
        background-position: bottom -150px right -270px;
        background-size: 740px auto;

        &__inner {
            display: flex;
            gap: 70px;
        }

        &__title {
            font-size: 13px;
            margin-bottom: 12px;
        }

        &__menu {
    
            &-item:not(:last-child) {
                margin-bottom: 6px;
            }
        }

        &__content {
            margin-bottom: 16px;
        }
    }
}

@media only screen and (min-width: 1440px) {
    .gen-footer {
        font-size: 18px;
        padding-top: 100px;
        padding-bottom: 100px;
        background-position: bottom -60px right -220px;
        background-size: 780px auto;

        &__title {
            font-size: 18px;
            margin-bottom: 32px;
        }

        &__menu {
    
            &-item:not(:last-child) {
                margin-bottom: 14px;
            }
        }

        &__content {
            margin-bottom: 32px;
        }

        &__leftside {

        }
    }
}

@media only screen and (min-width: 1680px) {
    .gen-footer {
        background-position: bottom -110px right -190px;
        background-size: 840px auto;

        &__leftside {
            flex: 0 0 50%;
        }
    }
}